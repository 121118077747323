import createPersistedState from "vuex-persistedstate";
import Vue from "vue";
import Vuex from "vuex";

import auth from "./modules/Auth";
import changes from "./modules/Changes";
import language from "./modules/Language";
import menu from "./modules/Menu";
import users from "./modules/Users";
import catalog from "./modules/catalog";
import categories from "./modules/categories";
import settings from "./modules/Settings";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      paths: ["auth.backurl", "auth.profile"],
    }),
  ],
  modules: {
    auth,
    changes,
    language,
    menu,
    users,
    catalog,
    categories,
    settings
  },
});
